import React from "react";
import pick from "lodash/pick";
import * as Yup from "yup";
import Select from "react-select";
import { Formik } from "formik";
import Error from "../../../form/Error";
import Label from "../../../form/Label";
import { TextArea } from "../../../form/Input";
import CheckboxWithInput from "../../../form/CheckboxWithInput";
import BreathSoundsTable from "./BreathSoundsTable";
import FormCheckbox from "../../../form/FormCheckbox";
import SecondaryButton from "../../../SecondaryButton";
import SaveButton from "../../../form/SaveButton";
import {
  pulmonaryChestFields,
  pulmonaryBreathSoundsFields,
  pulmonaryRespiratoryFields,
  pulmonaryQualityFields,
  pulmonaryCoughFields,
  pulmonarySputumFields,
  pulmonaryOxygenFields,
} from "../../../../utils/assessmentUtils";
import {
  pulmonaryAssessmentMappings,
  CONTINUE,
  EXIT,
} from "../../../../constants";

export const breathSoundsOptions = [
  {
    value: "clear",
    label: "Clear",
  },
  {
    value: "rales",
    label: "Rales",
  },
  {
    value: "rhonchi",
    label: "Rhonchi",
  },
  {
    value: "wheezes",
    label: "Wheezes",
  },
  {
    value: "coarse",
    label: "Coarse",
  },
  {
    value: "diminished",
    label: "Diminished",
  },
  {
    value: "crackles",
    label: "Crackles",
  },
  {
    value: "rub",
    label: "Rub",
  },
];

export const sputumAmountOptions = [
  {
    value: "scant",
    label: "Scant",
  },
  {
    value: "moderate",
    label: "Moderate",
  },
  {
    value: "copious",
    label: "Copious",
  },
];

export const sputumColorOptions = [
  {
    value: "clear",
    label: "Clear",
  },
  {
    value: "white_gray",
    label: "White/Gray",
  },
  {
    value: "yellow_green",
    label: "Dark Yellow/Green",
  },
  {
    value: "brown",
    label: "Brown",
  },
  {
    value: "pink_bloody",
    label: "Pink/Bloody",
  },
];

const oxygenLiterOptions = Array(30)
  .fill()
  .map((_, i) => {
    const value = (i + 1) / 2;
    return { value: value, label: value };
  });

export const oxygenDeliveryMethodOptions = [
  {
    value: "nasal_cannula",
    label: "Nasal Cannula",
  },
  {
    value: "trach_collar",
    label: "Trach Collar",
  },
  {
    value: "venti_mask",
    label: "Venti Mask",
  },
  {
    value: "face_mask",
    label: "Face Mask",
  },
  {
    value: "t_piece",
    label: "T-Piece",
  },
  {
    value: "non_rebreather",
    label: "Non-Rebreather",
  },
];

const breathSoundsSelectionMessage = "You must select at least one option";

const validationSchema = Yup.object().shape({
  chest_expansion: Yup.object().shape({
    chest_expansion_symmetry: Yup.string().required(
      "You must enter a value for chest expansion symmetry"
    ),
    chest_expansion_barrel: Yup.boolean(),
  }),
  breath_sounds: Yup.object().shape({
    breath_sounds_right: Yup.array()
      .ensure()
      .min(1, `Right: ${breathSoundsSelectionMessage}`)
      .test(
        "breath_sounds_right_test",
        "Right: If Clear is checked in this column, the only acceptable other options are Coarse or Diminished",
        (breath_sounds_right) => {
          return (
            !breath_sounds_right.includes("clear") ||
            breath_sounds_right.every((x) =>
              ["clear", "coarse", "diminished"].includes(x)
            )
          );
        }
      ),
    breath_sounds_left: Yup.array()
      .ensure()
      .min(1, `Left: ${breathSoundsSelectionMessage}`)
      .test(
        "breath_sounds_left_test",
        "Left: If Clear is checked in this column, the only acceptable other options are Coarse or Diminished",
        (breath_sounds_left) => {
          return (
            !breath_sounds_left.includes("clear") ||
            breath_sounds_left.every((x) =>
              ["clear", "coarse", "diminished"].includes(x)
            )
          );
        }
      ),
    breath_sounds_upper: Yup.array()
      .ensure()
      .min(1, `Upper: ${breathSoundsSelectionMessage}`)
      .test(
        "breath_sounds_upper_test",
        "Upper: If Clear is checked in this column, the only acceptable other options are Coarse or Diminished",
        (breath_sounds_upper) => {
          return (
            !breath_sounds_upper.includes("clear") ||
            breath_sounds_upper.every((x) =>
              ["clear", "coarse", "diminished"].includes(x)
            )
          );
        }
      ),
    breath_sounds_lower: Yup.array()
      .ensure()
      .min(1, `Lower: ${breathSoundsSelectionMessage}`)
      .test(
        "breath_sounds_lower_test",
        "Lower: If Clear is checked in this column, the only acceptable other options are Coarse or Diminished",
        (breath_sounds_lower) => {
          return (
            !breath_sounds_lower.includes("clear") ||
            breath_sounds_lower.every((x) =>
              ["clear", "coarse", "diminished"].includes(x)
            )
          );
        }
      ),
    breath_sounds_coarse: Yup.string(),
  }),
  respiratory: Yup.object().shape({
    respiratory_depth: Yup.string(),
    respiratory_accessory: Yup.boolean(),
  }),
  pattern_labor: Yup.string(),
  quality: Yup.object().shape({
    quality_nasal_breathing: Yup.boolean(),
    quality_apnea: Yup.boolean(),
    quality_dyspnea: Yup.boolean(),
    quality_tachypnea: Yup.boolean(),
    quality_accessory: Yup.boolean(),
    quality_nasal_flaring: Yup.boolean(),
    quality_pursed: Yup.boolean(),
  }),
  cough: Yup.object().shape({
    cough_presence: Yup.string().required(
      "You must select a value for cough presence"
    ),
    cough_productive: Yup.string(),
    cough_wetness: Yup.string(),
    cough_hacking: Yup.boolean(),
  }),
  sputum: Yup.object().shape({
    sputum_thickness: Yup.string(),
    sputum_foul_odor: Yup.boolean(),
    sputum_amount: Yup.string(),
    sputum_color: Yup.string(),
  }),
  oxygen: Yup.object().shape({
    oxygen_administered: Yup.boolean(),
    oxygen_liters: Yup.string().when("oxygen_administered", {
      is: true,
      then: Yup.string().required("You must enter an amount of oxygen"),
      otherwise: null,
    }),
    oxygen_delivery_method: Yup.string().when("oxygen_administered", {
      is: true,
      then: Yup.string().required("You must enter a delivery method"),
      otherwise: null,
    }),
    oxygen_continuity: Yup.string().when("oxygen_administered", {
      is: true,
      then: Yup.string().required("You must enter Continuous or PRN"),
      otherwise: null,
    }),
    oxygen_trach: Yup.boolean(),
    oxygen_trach_value: Yup.string().when("oxygen_trach", {
      is: true,
      then: Yup.string().required("You must enter a Trach value").max(60),
      otherwise: null,
    }),
    oxygen_cpap: Yup.boolean(),
    oxygen_cpap_value: Yup.string().when("oxygen_cpap", {
      is: true,
      then: Yup.string().required("You must enter a CPAP value").max(60),
      otherwise: null,
    }),
    oxygen_bipap: Yup.boolean(),
    oxygen_bipap_value: Yup.string().when("oxygen_bipap", {
      is: true,
      then: Yup.string().required("You must enter a BI-PAP value").max(60),
      otherwise: null,
    }),
    oxygen_apap: Yup.boolean(),
    oxygen_apap_value: Yup.string().when("oxygen_apap", {
      is: true,
      then: Yup.string().required("You must enter an APAP value").max(60),
      otherwise: null,
    }),
  }),
  abnormal_pulmonary_findings: Yup.string(),
});

class PulmonaryAssessmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.initialValues = {
      chest_expansion: {
        ...pick(props.assessment, pulmonaryChestFields),
      },
      breath_sounds: {
        ...pick(props.assessment, pulmonaryBreathSoundsFields),
      },
      respiratory: {
        ...pick(props.assessment, pulmonaryRespiratoryFields),
      },
      pattern_labor: props.assessment ? props.assessment.pattern_labor : "",
      quality: {
        ...pick(props.assessment, pulmonaryQualityFields),
      },
      cough: {
        ...pick(props.assessment, pulmonaryCoughFields),
      },
      sputum: {
        ...pick(props.assessment, pulmonarySputumFields),
      },
      oxygen: {
        ...pick(props.assessment, pulmonaryOxygenFields),
      },
      abnormal_pulmonary_findings: props.assessment
        ? props.assessment.abnormal_pulmonary_findings
        : "",
    };
    this.formRef = React.createRef();
  }

  _handleSubmit = (values) => {
    this.props.onSubmit({
      id: this.props.assessment.id,
      ...values.chest_expansion,
      ...values.breath_sounds,
      ...values.respiratory,
      ...values.quality,
      ...values.cough,
      ...values.sputum,
      ...values.oxygen,
      pattern_labor: values.pattern_labor,
      abnormal_pulmonary_findings: values.abnormal_pulmonary_findings,
    });

    if (this.state.submitType === CONTINUE) {
      this.props.onContinue();
    } else {
      this.props.onCancel();
    }
  };
  render() {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onSubmit={this._handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          submitForm,
          isSubmitting,
          isValidating,
        }) => {
          if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
            this.formRef.current.scrollIntoView({ behavior: "smooth" });
          }

          const renderError = (category, key) => {
            return (
              errors[category] &&
              errors[category][key] && (
                <Error id={`error_${key}`}>{errors[category][key]}</Error>
              )
            );
          };

          const clearOxygenValues = () => {
            setFieldValue("oxygen.oxygen_liters", "");
            setFieldValue("oxygen.oxygen_delivery_method", "");
            setFieldValue("oxygen.oxygen_continuity", "");
          };

          return (
            <form
              onSubmit={handleSubmit}
              ref={this.formRef}
              id="pulmonary-assessment-form"
            >
              <div className="p-6">
                <div className="mb-12 w-full">
                  <h3
                    id="chest-expansion-heading"
                    className="text-xl text-gray-800 font-bold mb-6"
                  >
                    Chest Expansion (Required)
                  </h3>

                  <div className="flex flex-row flex-wrap">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "chest_expansion.chest_expansion_symmetry",
                          "symmetrical"
                        )
                      }
                      checked={
                        values.chest_expansion.chest_expansion_symmetry ===
                        "symmetrical"
                      }
                      id="chest_expansion_symmetry_symmetrical_checkbox"
                    >
                      Symmetrical
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "chest_expansion.chest_expansion_symmetry",
                          "asymmetrical"
                        )
                      }
                      checked={
                        values.chest_expansion.chest_expansion_symmetry ===
                        "asymmetrical"
                      }
                      id="chest_expansion_symmetry_asymmetrical_checkbox"
                    >
                      Asymmetrical
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "chest_expansion.chest_expansion_barrel",
                          !values.chest_expansion.chest_expansion_barrel
                        )
                      }
                      checked={!!values.chest_expansion.chest_expansion_barrel}
                      id="chest_expansion_symmetry_barrel_checkbox"
                    >
                      Barrel Chested
                    </FormCheckbox>
                  </div>

                  {renderError("chest_expansion", "chest_expansion_barrel")}
                  {renderError("chest_expansion", "chest_expansion_symmetry")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="breath-sounds-heading"
                    className="text-xl text-gray-800 font-bold mb-6"
                  >
                    Breath Sounds (Required)
                  </h3>
                  <BreathSoundsTable
                    options={breathSoundsOptions}
                    values={values.breath_sounds}
                    onCheck={setFieldValue}
                  />

                  {renderError("breath_sounds", "breath_sounds_right")}
                  {renderError("breath_sounds", "breath_sounds_left")}
                  {renderError("breath_sounds", "breath_sounds_upper")}
                  {renderError("breath_sounds", "breath_sounds_lower")}
                  {renderError("breath_sounds", "breath_sounds_coarse")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="respiratory-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Respiratory
                  </h3>
                  <div className="flex flex-row flex-wrap">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("respiratory.respiratory_depth", "deep")
                      }
                      checked={values.respiratory.respiratory_depth === "deep"}
                      id="respiratory_depth_deep_checkbox"
                    >
                      Deep
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "respiratory.respiratory_depth",
                          "shallow"
                        )
                      }
                      checked={
                        values.respiratory.respiratory_depth === "shallow"
                      }
                      id="respiratory_depth_shallow_checkbox"
                    >
                      Shallow
                    </FormCheckbox>
                    <FormCheckbox
                      className="mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "respiratory.respiratory_accessory",
                          !values.respiratory.respiratory_accessory
                        )
                      }
                      checked={!!values.respiratory.respiratory_accessory}
                      id="respiratory_accessory_checkbox"
                    >
                      Use of accessory muscles
                    </FormCheckbox>
                  </div>
                  {renderError("respiratory", "respiratory_depth")}
                  {renderError("respiratory", "respiratory_accessory")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="pattern-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Pattern
                  </h3>
                  <div className="flex flex-row flex-wrap">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue("pattern_labor", "labored")}
                      checked={values.pattern_labor === "labored"}
                      id="pattern_labor_labored_checkbox"
                    >
                      Labored
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("pattern_labor", "unlabored")
                      }
                      checked={values.pattern_labor === "unlabored"}
                      id="pattern_labor_unlabored_checkbox"
                    >
                      Unlabored
                    </FormCheckbox>
                  </div>
                  {renderError("pattern_labor")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="quality-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Quality
                  </h3>
                  <div className="flex flex-row flex-wrap">
                    {pulmonaryQualityFields.map((field, index) => (
                      <FormCheckbox
                        key={index}
                        className="mb-4 mr-4 p-2 flex items-center"
                        onClick={() =>
                          setFieldValue(
                            `quality.${field}`,
                            !values.quality[field]
                          )
                        }
                        checked={!!values.quality[field]}
                        id={`quality_${field}_checkbox`}
                      >
                        {pulmonaryAssessmentMappings[field]}
                      </FormCheckbox>
                    ))}
                  </div>
                  {renderError("quality", "quality_nasal_breathing")}
                  {renderError("quality", "quality_apnea")}
                  {renderError("quality", "quality_dyspnea")}
                  {renderError("quality", "quality_tachypnea")}
                  {renderError("quality", "quality_accessory")}
                  {renderError("quality", "quality_nasal_flaring")}
                  {renderError("quality", "quality_pursed")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="cough-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Cough (Required)
                  </h3>
                  <div className="flex flex-row flex-wrap">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("cough.cough_presence", "absent")
                      }
                      checked={values.cough.cough_presence === "absent"}
                      id="cough_presence_absent_checkbox"
                    >
                      Absent
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("cough.cough_presence", "present")
                      }
                      checked={values.cough.cough_presence === "present"}
                      id="cough_presence_present_checkbox"
                    >
                      Present
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("cough.cough_productive", "productive")
                      }
                      checked={values.cough.cough_productive === "productive"}
                      id="cough_productive_productive_checkbox"
                    >
                      Productive
                    </FormCheckbox>
                    <FormCheckbox
                      className="mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("cough.cough_productive", "nonproductive")
                      }
                      checked={
                        values.cough.cough_productive === "nonproductive"
                      }
                      id="cough_productive_nonproductive_checkbox"
                    >
                      Non-Productive
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("cough.cough_wetness", "wet")
                      }
                      checked={values.cough.cough_wetness === "wet"}
                      id="cough_wetness_wet_checkbox"
                    >
                      Wet
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("cough.cough_wetness", "dry")
                      }
                      checked={values.cough.cough_wetness === "dry"}
                      id="cough_wetness_dry_checkbox"
                    >
                      Dry
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "cough.cough_hacking",
                          !values.cough.cough_hacking
                        )
                      }
                      checked={!!values.cough.cough_hacking}
                      id="cough_hacking_checkbox"
                    >
                      Hacking
                    </FormCheckbox>
                  </div>

                  {renderError("cough", "cough_presence")}
                  {renderError("cough", "cough_productive")}
                  {renderError("cough", "cough_wetness")}
                  {renderError("cough", "cough_hacking")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="sputum-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Sputum
                  </h3>
                  <div className="flex flex-row flex-wrap items-end">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("sputum.sputum_thickness", "thin")
                      }
                      checked={values.sputum.sputum_thickness === "thin"}
                      id="sputum_thickness_thin_checkbox"
                    >
                      Thin
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("sputum.sputum_thickness", "thick")
                      }
                      checked={values.sputum.sputum_thickness === "thick"}
                      id="sputum_thickness_thick_checkbox"
                    >
                      Thick
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue(
                          "sputum.sputum_foul_odor",
                          !values.sputum.sputum_foul_odor
                        )
                      }
                      checked={!!values.sputum.sputum_foul_odor}
                      id="sputum_thickness_foul_odor_checkbox"
                    >
                      Foul Odor
                    </FormCheckbox>

                    <div className="mb-4 mr-4">
                      <Label htmlFor="sputum.sputum_amount">Amount</Label>

                      <Select
                        id="sputum_amount"
                        name="sputum.sputum_amount"
                        value={sputumAmountOptions.find(
                          (opt) => opt.value === values.sputum.sputum_amount
                        )}
                        options={sputumAmountOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, _) => {
                          setFieldValue("sputum.sputum_amount", option.value);
                        }}
                        components={{ IndicatorSeparator: null }}
                      />
                    </div>

                    <div className="mb-4 mr-4">
                      <Label htmlFor="sputum.sputum_amount">Color</Label>

                      <Select
                        id="sputum_color"
                        name="sputum.sputum_color"
                        value={sputumColorOptions.find(
                          (opt) => opt.value === values.sputum.sputum_color
                        )}
                        options={sputumColorOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, _) => {
                          setFieldValue("sputum.sputum_color", option.value);
                        }}
                        components={{ IndicatorSeparator: null }}
                      />
                    </div>
                  </div>

                  {renderError("sputum", "sputum_thickness")}
                  {renderError("sputum", "sputum_foul_odor")}
                  {renderError("sputum", "sputum_amount")}
                  {renderError("sputum", "sputum_color")}
                </div>

                <div className="mb-12 w-full">
                  <h3
                    id="oxygen-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Oxygen
                  </h3>
                  <div className="flex flex-row flex-wrap items-end">
                    <FormCheckbox
                      className="mb-4 mr-4 p-2 flex items-center"
                      onClick={() => {
                        if (values.oxygen.oxygen_administered) {
                          clearOxygenValues();
                        }
                        setFieldValue(
                          "oxygen.oxygen_administered",
                          !values.oxygen.oxygen_administered
                        );
                      }}
                      checked={!!values.oxygen.oxygen_administered}
                      id="oxygen_administered_checkbox"
                    >
                      Oxygen Administered
                    </FormCheckbox>

                    <div className="mb-4 mr-4">
                      <Label htmlFor="oxygen.oxygen_liters">Liters</Label>

                      <Select
                        id="oxygen_liters"
                        name="oxygen.oxygen_liters"
                        value={
                          oxygenLiterOptions.find(
                            (opt) => opt.value === values.oxygen.oxygen_liters
                          ) || ""
                        }
                        options={oxygenLiterOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, _) => {
                          setFieldValue("oxygen.oxygen_liters", option.value);
                        }}
                        components={{ IndicatorSeparator: null }}
                      />
                    </div>

                    <div className="mb-4 mr-4">
                      <Label htmlFor="oxygen.oxygen_delivery_method">
                        Delivery Method
                      </Label>

                      <Select
                        id="oxygen_delivery_method"
                        name="oxygen.oxygen_delivery_method"
                        value={
                          oxygenDeliveryMethodOptions.find(
                            (opt) =>
                              opt.value === values.oxygen.oxygen_delivery_method
                          ) || ""
                        }
                        options={oxygenDeliveryMethodOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, _) => {
                          setFieldValue(
                            "oxygen.oxygen_delivery_method",
                            option.value
                          );
                        }}
                        components={{ IndicatorSeparator: null }}
                      />
                    </div>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => {
                        if (values.oxygen.oxygen_continuity === "continuous") {
                          setFieldValue("oxygen.oxygen_continuity", "");
                        } else {
                          setFieldValue(
                            "oxygen.oxygen_continuity",
                            "continuous"
                          );
                        }
                      }}
                      checked={values.oxygen.oxygen_continuity === "continuous"}
                      id="oxygen_continuity_continuous_checkbox"
                    >
                      Continuous
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => {
                        if (values.oxygen.oxygen_continuity === "prn") {
                          setFieldValue("oxygen.oxygen_continuity", "");
                        } else {
                          setFieldValue("oxygen.oxygen_continuity", "prn");
                        }
                      }}
                      checked={values.oxygen.oxygen_continuity === "prn"}
                      id="oxygen_continuity_prn_checkbox"
                    >
                      PRN
                    </FormCheckbox>
                  </div>

                  {renderError("oxygen", "oxygen_liters")}
                  {renderError("oxygen", "oxygen_delivery_method")}
                  {renderError("oxygen", "oxygen_continuity")}
                </div>

                <div className="mb-12 w-full">
                  <h3 className="text-xl text-gray-800 font-bold mb-4">
                    Airway Assisted Devices
                  </h3>

                  <div className="flex flex-row flex-wrap">
                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "oxygen.oxygen_trach",
                          !values.oxygen.oxygen_trach
                        );
                        if (values.oxygen.oxygen_trach_value) {
                          setFieldValue("oxygen.oxygen_trach_value", "");
                        }
                      }}
                      checked={!!values.oxygen.oxygen_trach}
                      onInputChange={handleChange}
                      checkboxTitle="Trach"
                      inputName="oxygen.oxygen_trach_value"
                      inputValue={values.oxygen.oxygen_trach_value}
                      inputPlaceholder="Please clarify..."
                    />

                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "oxygen.oxygen_cpap",
                          !values.oxygen.oxygen_cpap
                        );
                        if (values.oxygen.oxygen_cpap_value) {
                          setFieldValue("oxygen.oxygen_cpap_value", "");
                        }
                      }}
                      checked={!!values.oxygen.oxygen_cpap}
                      onInputChange={handleChange}
                      checkboxTitle="CPAP"
                      inputName="oxygen.oxygen_cpap_value"
                      inputValue={values.oxygen.oxygen_cpap_value}
                      inputPlaceholder="Please clarify..."
                    />

                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "oxygen.oxygen_bipap",
                          !values.oxygen.oxygen_bipap
                        );
                        if (values.oxygen.oxygen_bipap_value) {
                          setFieldValue("oxygen.oxygen_bipap_value", "");
                        }
                      }}
                      checked={!!values.oxygen.oxygen_bipap}
                      onInputChange={handleChange}
                      checkboxTitle="BI-PAP"
                      inputName="oxygen.oxygen_bipap_value"
                      inputValue={values.oxygen.oxygen_bipap_value}
                      inputPlaceholder="Please clarify..."
                    />

                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "oxygen.oxygen_apap",
                          !values.oxygen.oxygen_apap
                        );
                        if (values.oxygen.oxygen_apap_value) {
                          setFieldValue("oxygen.oxygen_apap_value", "");
                        }
                      }}
                      checked={!!values.oxygen.oxygen_apap}
                      onInputChange={handleChange}
                      checkboxTitle="APAP"
                      inputName="oxygen.oxygen_apap_value"
                      inputValue={values.oxygen.oxygen_apap_value}
                      inputPlaceholder="Please clarify..."
                    />
                  </div>

                  {renderError("oxygen", "oxygen_trach_value")}
                  {renderError("oxygen", "oxygen_cpap_value")}
                  {renderError("oxygen", "oxygen_bipap_value")}
                  {renderError("oxygen", "oxygen_apap_value")}
                </div>

                <div className="mb-12 w-1/2">
                  <h3
                    id="abnormal-findings-heading"
                    className="text-xl text-gray-800 font-bold mb-4"
                  >
                    Abnormal Findings
                  </h3>
                  <TextArea
                    id="abnormal_pulmonary_findings_textarea"
                    type="textarea"
                    autoComplete="off"
                    name="abnormal_pulmonary_findings"
                    value={values.abnormal_pulmonary_findings}
                    onChange={handleChange}
                    className="resize-none h-48"
                  />
                  {renderError("abnormal_pulmonary_findings")}
                </div>
              </div>
              <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
                <SecondaryButton
                  id="cancel-button"
                  className="inline-block mr-4"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </SecondaryButton>
                <SaveButton
                  id="save-exit"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ submitType: EXIT }, () => submitForm());
                  }}
                >
                  Save and Exit
                </SaveButton>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default PulmonaryAssessmentForm;
