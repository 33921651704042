import React from 'react'
import pick from 'lodash/pick';
import * as Yup from 'yup'
import Select from 'react-select'
import { Formik } from 'formik'
import Error from '../../../form/Error'
import Label from '../../../form/Label'
import { TextArea, InputWithCharsLeft } from '../../../form/Input'
import FormCheckbox from '../../../form/FormCheckbox'
import SecondaryButton from '../../../SecondaryButton'
import SaveButton from '../../../form/SaveButton'
import {
  cardioApicalFields,
  cardioEdemaFields,
  cardioCapillaryRefillFields,
  cardioPedalPulsesFields,
  cardioRadialPulsesFields,
} from '../../../../utils/assessmentUtils'
import { CONTINUE, EXIT } from '../../../../constants'

export const edemaGradeOptions = [
  {value: '', label: 'N/A'},
  {value: 1, label: '1+ Barely Detectable'},
  {value: 2, label: '2+ Slight indentation (15 secs to rebound)'},
  {value: 3, label: '3+ Deeper indentation (30 secs to rebound)'},
  {value: 4, label: '4+ >30 secs to rebound'}
]

export const pulsesStrengthOptions = [
  {value: 0, label: '0 - No Pulse'},
  {value: 1, label: '1 - Diminished'},
  {value: 2, label: '2 - Slightly diminshed'},
  {value: 3, label: '3 - Normal palpable'},
  {value: 4, label: '4 - Bounding'}
]

const validationSchema = Yup.object().shape({
  apical: Yup.object().shape({
    apical_rhythm: Yup.string().required('You must add a value for apical rhythm')
  }),
  edema: Yup.object().shape({
    edema_jvd: Yup.boolean(),
    edema_pitting: Yup.string().when('edema_jvd', {
      is: (edema_jvd) => edema_jvd === true,
      then: Yup.string().required('You must enter a pitting value'),
      otherwise: null
    }),
    edema_location: Yup.string().when('edema_pitting', {
      is: (edema_pitting) => ['pitting', 'non_pitting'].includes(edema_pitting),
      then: Yup.string().required('You must enter a location value').max(60),
      otherwise: null
    }),
    edema_grade: Yup.string().when('edema_pitting', {
      is: 'pitting',
      then: Yup.string().required('You must enter a grade value'),
      otherwise: null
    })
  }),
  capillary_refill: Yup.object().shape({
    capillary_refill_timing: Yup.string().required('You must enter a timing value'),
    capillary_refill_clubbing: Yup.boolean()
  }),
  pedal_pulses: Yup.object().shape({
    pedal_pulses_presence_left: Yup.string().required('You must enter a presence value (L)'),
    pedal_pulses_presence_right: Yup.string().required('You must enter a presence value (R)'),
    pedal_pulses_strength_left: Yup.string().required('You must select a strength value (L)'),
    pedal_pulses_strength_right: Yup.string().required('You must select a strength value (R)')
  }),
  radial_pulses: Yup.object().shape({
    radial_pulses_presence_left: Yup.string().required('You must enter a presence value (L)'),
    radial_pulses_presence_right: Yup.string().required('You must enter a presence value (R)'),
    radial_pulses_strength_left: Yup.string().required('You must select a strength value (L)'),
    radial_pulses_strength_right: Yup.string().required('You must select a strength value (R)')
  }),
  abnormal_cardiovascular_findings: Yup.string()

})

class CardiovascularAssessmentForm extends React.Component {
  constructor(props) {
    super(props)

    this.initialValues = {
      apical: {
        ...pick(props.assessment, cardioApicalFields)
      },
      edema: {
        ...pick(props.assessment, cardioEdemaFields)
      },
      capillary_refill: {
        ...pick(props.assessment, cardioCapillaryRefillFields)
      },
      pedal_pulses: {
        ...pick(props.assessment, cardioPedalPulsesFields)
      },
      radial_pulses: {
        ...pick(props.assessment, cardioRadialPulsesFields)
      },
      abnormal_cardiovascular_findings: props.assessment ? props.assessment.abnormal_cardiovascular_findings : ''
    }
    this.formRef = React.createRef();
  }

  _handleSubmit = (values) => {

    this.props.onSubmit({
      id: this.props.assessment.id,
      ...values.apical,
      ...values.edema,
      ...values.capillary_refill,
      ...values.pedal_pulses,
      ...values.radial_pulses,
      abnormal_cardiovascular_findings: values.abnormal_cardiovascular_findings
    });

    if(this.state.submitType === CONTINUE) {
      this.props.onContinue();
    } else {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onSubmit={this._handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          submitForm,
          isSubmitting,
          isValidating
        }) => {
          if(Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
            this.formRef.current.scrollIntoView({behavior: 'smooth'});
          }

          const renderError = (category, key) => {
            return errors[category] &&
              errors[category][key] &&
              <Error id={`error_${key}`}>{errors[category][key]}</Error>
          }

          return (
            <form onSubmit={handleSubmit} ref={this.formRef} id="cardiovascular-assessment-form">
              <div className="p-6">
                <div className="mb-12 w-full">
                  <h3 id="apical-heading" className="text-xl text-gray-800 font-bold mb-6">Apical (Required)</h3>
                  <div className="flex flex-row flex-wrap" >
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('apical.apical_rhythm', 'regular')}
                      checked={values.apical.apical_rhythm === 'regular' }
                      id="apical_apical_rhythm_regular_checkbox"
                    >
                      Regular
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('apical.apical_rhythm', 'irregular')}
                      checked={values.apical.apical_rhythm === 'irregular' }
                      id="apical_apical_rhythm_irregular_checkbox"
                    >
                      Irregular
                    </FormCheckbox>
                  </div>

                  {renderError('apical', 'apical_rhythm')}
                </div>

                <div className="mb-12 w-full">
                  <h3 id="edema-heading" className="text-xl text-gray-800 font-bold mb-4">Edema</h3>
                  <div className="mt-6 flex items-end flex-row flex-wrap">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('edema.edema_jvd', !values.edema.edema_jvd)}
                      checked={!!values.edema.edema_jvd}
                      id="edema_jvd_checkbox"
                    >
                      JVD
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => {
                          if(values.edema.edema_pitting === 'non_pitting') {
                            setFieldValue('edema.edema_pitting', '')
                          } else {
                            setFieldValue('edema.edema_pitting', 'non_pitting')
                          }
                      }}
                      checked={values.edema.edema_pitting === 'non_pitting' }
                      id="edema_nonpitting_checkbox"
                    >
                      Non-Pitting
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => {
                        if(values.edema.edema_pitting === 'pitting') {
                          setFieldValue('edema.edema_pitting', '')
                        } else {
                          setFieldValue('edema.edema_pitting', 'pitting')
                        }
                      }}
                      checked={values.edema.edema_pitting === 'pitting' }
                      id="edema_pitting_checkbox"
                    >
                      Pitting
                    </FormCheckbox>

                    <div className="mb-4 mr-4">
                      <Label htmlFor="edema.edema_location">Location</Label>
                      <InputWithCharsLeft
                        name="edema.edema_location"
                        type="text"
                        value={values.edema.edema_location}
                        onChange={handleChange}
                        id="edema_location"
                        className="py-2"
                        maxLength="60"
                      />
                    </div>

                    <div className="mb-4">
                      <Label htmlFor="edema.edema_grade">Grade</Label>
                      <Select
                        id="edema_grade"
                        name="edema.edema_grade"
                        value={
                          edemaGradeOptions.find(opt => opt.value === values.edema.edema_grade)
                        }
                        options={edemaGradeOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, action) => {
                          setFieldValue('edema.edema_grade', option.value)
                        }}
                        components={{IndicatorSeparator: null}}
                      />
                    </div>
                  </div>

                  {renderError('edema', 'edema_na')}
                  {renderError('edema', 'edema_jvd')}
                  {renderError('edema', 'edema_pitting')}
                  {renderError('edema', 'edema_location')}
                  {renderError('edema', 'edema_grade')}
                </div>

                <div className="mb-12 w-full">
                  <h3 id="capillary-refill-heading" className="text-xl text-gray-800 font-bold mb-6">Capillary Refill (Required)</h3>
                  <div className="flex flex-row flex-wrap" >
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('capillary_refill.capillary_refill_timing', 'lt_three')}
                      checked={values.capillary_refill.capillary_refill_timing === 'lt_three' }
                      id="capillary_refill_timing_lt_checkbox"
                    >
                      &lt; 3 seconds
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('capillary_refill.capillary_refill_timing', 'gt_three')}
                      checked={values.capillary_refill.capillary_refill_timing === 'gt_three' }
                      id="capillary_refill_timing_gt_checkbox"
                    >
                      &gt; 3 seconds
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('capillary_refill.capillary_refill_clubbing', !values.capillary_refill.capillary_refill_clubbing)}
                      checked={!!values.capillary_refill.capillary_refill_clubbing}
                      id="capillary_refill_clubbing_checkbox"
                    >
                      Clubbing
                    </FormCheckbox>
                  </div>
                  {renderError('capillary_refill', 'capillary_refill_timing')}
                  {renderError('capillary_refill', 'capillary_refill_clubbing')}
                </div>


                <div className="mb-12 w-full" >
                  <h3 id="pedal-pulses-heading" className="text-xl text-gray-800 font-bold mb-4">Pedal Pulses (Required)</h3>
                  <div className="mt-6 flex items-end flex-row flex-wrap" >
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('pedal_pulses.pedal_pulses_presence_left', 'present')}
                      checked={values.pedal_pulses.pedal_pulses_presence_left === 'present' }
                      id="pedal_pulses_presence_left_present_checkbox"
                    >
                      Present (L)
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('pedal_pulses.pedal_pulses_presence_left', 'absent')}
                      checked={values.pedal_pulses.pedal_pulses_presence_left === 'absent' }
                      id="pedal_pulses_presence_left_absent_checkbox"
                    >
                      Absent (L)
                    </FormCheckbox>
                    <div className="mb-4 mr-4">
                      <Label htmlFor="pedal_pulses.pedal_pulses_strength_left">Strength (L)</Label>
                      <Select
                        id="pedal_pulses_strength_left"
                        name="pedal_pulses.pedal_pulses_strength_left"
                        value={
                          pulsesStrengthOptions.find(opt => opt.value === values.pedal_pulses.pedal_pulses_strength_left)
                        }
                        options={pulsesStrengthOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, action) => {
                          setFieldValue('pedal_pulses.pedal_pulses_strength_left', option.value)
                        }}
                        components={{IndicatorSeparator: null}}
                      />
                    </div>
                  </div>

                  {renderError('pedal_pulses', 'pedal_pulses_presence_left')}
                  {renderError('pedal_pulses', 'pedal_pulses_strength_left')}
                  <div className="mt-6 flex items-end flex-row flex-wrap" >
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('pedal_pulses.pedal_pulses_presence_right', 'present')}
                      checked={values.pedal_pulses.pedal_pulses_presence_right === 'present' }
                      id="pedal_pulses_presence_right_present_checkbox"
                    >
                      Present (R)
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('pedal_pulses.pedal_pulses_presence_right', 'absent')}
                      checked={values.pedal_pulses.pedal_pulses_presence_right === 'absent' }
                      id="pedal_pulses_presence_right_absent_checkbox"
                    >
                      Absent (R)
                    </FormCheckbox>

                    <div className="mb-4">
                      <Label htmlFor="pedal_pulses.pedal_pulses_strength_right">Strength (R)</Label>
                      <Select
                        id="pedal_pulses_strength_right"
                        name="pedal_pulses.pedal_pulses_strength_right"
                        value={
                          pulsesStrengthOptions.find(opt => opt.value === values.pedal_pulses.pedal_pulses_strength_right)
                        }
                        options={pulsesStrengthOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, action) => {
                          setFieldValue('pedal_pulses.pedal_pulses_strength_right', option.value)
                        }}
                        components={{IndicatorSeparator: null}}
                      />
                    </div>

                  </div>
                  {renderError('pedal_pulses', 'pedal_pulses_presence_right')}
                  {renderError('pedal_pulses', 'pedal_pulses_strength_right')}
                </div>

                <div className="mb-12 w-full" >
                  <h3 id="radial-pulses-heading" className="text-xl text-gray-800 font-bold mb-4">Radial Pulses (Required)</h3>
                  <div className="mt-6 flex items-end flex-row flex-wrap" >
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('radial_pulses.radial_pulses_presence_left', 'present')}
                      checked={values.radial_pulses.radial_pulses_presence_left === 'present' }
                      id="radial_pulses_presence_left_present_checkbox"
                    >
                      Present (L)
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('radial_pulses.radial_pulses_presence_left', 'absent')}
                      checked={values.radial_pulses.radial_pulses_presence_left === 'absent' }
                      id="radial_pulses_presence_left_absent_checkbox"
                    >
                      Absent (L)
                    </FormCheckbox>
                    <div className="mb-4 mr-4">
                      <Label htmlFor="radial_pulses.radial_pulses_strength_left">Strength (L)</Label>
                      <Select
                        id="radial_pulses_strength_left"
                        name="radial_pulses.radial_pulses_strength_left"
                        value={
                          pulsesStrengthOptions.find(opt => opt.value === values.radial_pulses.radial_pulses_strength_left)
                        }
                        options={pulsesStrengthOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, action) => {
                          setFieldValue('radial_pulses.radial_pulses_strength_left', option.value)
                        }}
                        components={{IndicatorSeparator: null}}
                      />
                    </div>
                  </div>
                  {renderError('radial_pulses', 'radial_pulses_presence_left')}
                  {renderError('radial_pulses', 'radial_pulses_strength_left')}

                  <div className="mt-6 flex items-end flex-row flex-wrap" >
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('radial_pulses.radial_pulses_presence_right', 'present')}
                      checked={values.radial_pulses.radial_pulses_presence_right === 'present' }
                      id="radial_pulses_presence_right_present_checkbox"
                    >
                      Present (R)
                    </FormCheckbox>

                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => setFieldValue('radial_pulses.radial_pulses_presence_right', 'absent')}
                      checked={values.radial_pulses.radial_pulses_presence_right === 'absent' }
                      id="radial_pulses_presence_right_absent_checkbox"
                    >
                      Absent (R)
                    </FormCheckbox>

                    <div className="mb-4">
                      <Label htmlFor="radial_pulses.radial_pulses_strength_right">Strength (R)</Label>
                      <Select
                        id="radial_pulses_strength_right"
                        name="radial_pulses.radial_pulses_strength_right"
                        value={
                          pulsesStrengthOptions.find(opt => opt.value === values.radial_pulses.radial_pulses_strength_right)
                        }
                        options={pulsesStrengthOptions}
                        placeholder="Select one..."
                        className="react-select-container--clinical w-48"
                        classNamePrefix="react-select"
                        onChange={(option, action) => {
                          setFieldValue('radial_pulses.radial_pulses_strength_right', option.value)
                        }}
                        components={{IndicatorSeparator: null}}
                      />
                    </div>

                  </div>
                  {renderError('radial_pulses', 'radial_pulses_presence_right')}
                  {renderError('radial_pulses', 'radial_pulses_strength_right')}
                </div>

                <div className="mb-12 w-1/2" >
                  <h3 id="abnormal-findings-heading" className="text-xl text-gray-800 font-bold mb-4">Abnormal Findings</h3>
                  <TextArea
                    id="abnormal_cardiovascular_findings_textarea"
                    type="textarea"
                    autoComplete="off"
                    name="abnormal_cardiovascular_findings"
                    value={values.abnormal_cardiovascular_findings}
                    onChange={handleChange}
                    className="resize-none h-48"
                  />
                  {renderError('abnormal_cardiovascular_findings')}
                </div>
              </div>

              <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
                <SecondaryButton
                  id="cancel-button"
                  className="inline-block mr-4"
                  type="button"
                  onClick={this.props.onCancel}
                >Cancel</SecondaryButton>
                <SaveButton
                  id="save-exit"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({submitType: EXIT}, () => submitForm())}
                  }
                >Save and Exit</SaveButton>
                <SaveButton
                  id="save-continue"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({submitType: CONTINUE}, () => submitForm())}
                  }
                >Save and Continue</SaveButton>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }
}

export default CardiovascularAssessmentForm
